import RailsUJS from '@rails/ujs';
import { initBurgerMenu } from './burger-menu';
import { initTestimonials } from './testimonials';
import { initIndustries } from './industries';
import { initDemoBooking } from './book-demo';
import { initClientSlider } from './client-slider';
import { initAdditionalFeatures } from './additional-features';

global.$ = require('jquery');

RailsUJS.start();

$(document).ready(() => {
  initDemoBooking();
  initTestimonials();
  initIndustries();
  initBurgerMenu();
  initClientSlider();
  initAdditionalFeatures();
});
