const TESTIMONIALS_SELECTOR = {
  VIDEOS: '.testimonial',
  NEXT_BUTTON: '#testimonial-next',
  PREV_BUTTON: '#testimonial-prev',
};

export const initTestimonials = () => {
  let activeTestimonial = 0;

  const TESTIMONIALS_COUNT = document.querySelectorAll(TESTIMONIALS_SELECTOR.VIDEOS).length;
  const togglePosition = (node) => {
    if (!node) return;

    // this is because the YouTube player needs to be tricked into thinking its refreshing
    node.src = node.src;

    if (node.classList.contains('hidden')) {
      node.classList.remove('hidden');
      node.classList.add('absolute');
    }
    else {
      node.classList.remove('absolute');
      node.classList.add('hidden');
    }
  };

  const updateActiveTestimonialByDelta = (delta) => {
    const videos = document.querySelectorAll(TESTIMONIALS_SELECTOR.VIDEOS);
    togglePosition(videos[activeTestimonial]);

    activeTestimonial = (activeTestimonial + delta + TESTIMONIALS_COUNT) % TESTIMONIALS_COUNT;
    togglePosition(videos[activeTestimonial]);
  };

  document.querySelector(TESTIMONIALS_SELECTOR.NEXT_BUTTON)?.addEventListener('click', () => {
    updateActiveTestimonialByDelta(1);
  });

  document.querySelector(TESTIMONIALS_SELECTOR.PREV_BUTTON)?.addEventListener('click', () => {
    updateActiveTestimonialByDelta(-1);
  });

  togglePosition(document.querySelector(TESTIMONIALS_SELECTOR.VIDEOS));
};
