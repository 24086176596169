export const initBurgerMenu = () => {
  const mobileMenuButton = document.getElementById('mobile-menu-button');
  const mobileMenu = document.getElementById('mobile-menu');
  const hamburgerIcon = document.getElementById('hamburger-icon');
  const closeIcon = document.getElementById('close-icon');

  if (!mobileMenuButton || !mobileMenu) return;

  mobileMenuButton.addEventListener('click', () => {
    mobileMenu.classList.toggle('hidden');
    hamburgerIcon.classList.toggle('hidden');
    closeIcon.classList.toggle('hidden');

    const isExpanded = mobileMenu.classList.contains('hidden') ? 'false' : 'true';
    mobileMenuButton.setAttribute('aria-expanded', isExpanded);
  });

  document.addEventListener('click', (event) => {
    if (!mobileMenu.classList.contains('hidden')
            && !mobileMenu.contains(event.target)
            && !mobileMenuButton.contains(event.target)) {
      mobileMenu.classList.add('hidden');
      hamburgerIcon.classList.remove('hidden');
      closeIcon.classList.add('hidden');

      mobileMenuButton.setAttribute('aria-expanded', 'false');
    }
  });

  window.addEventListener('resize', () => {
    if (window.innerWidth >= 1024) {
      mobileMenu.classList.remove('hidden');
    }
    else if (mobileMenuButton.getAttribute('aria-expanded') !== 'true') {
      mobileMenu.classList.add('hidden');
    }
  });

  const navLinks = mobileMenu.querySelectorAll('a');
  navLinks.forEach((link) => {
    link.addEventListener('click', () => {
      if (window.innerWidth < 1024) {
        mobileMenu.classList.add('hidden');
        hamburgerIcon.classList.remove('hidden');
        closeIcon.classList.add('hidden');
        mobileMenuButton.setAttribute('aria-expanded', 'false');
      }
    });
  });
};
