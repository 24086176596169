const INDUSTRIES_SELECTOR = {
  IMAGES: '.skill-set-image',
  GRADIENT: '#skill-set-gradient',
  LEFT_BUTTON: '#skill-set-left',
  RIGHT_BUTTON: '#skill-set-right',
  DESCRIPTIONS: '.skill-set-desc',
};

export const initIndustries = () => {
  let activeIndustry = 0;
  const INDUSTRIES_COUNT = document.querySelectorAll(INDUSTRIES_SELECTOR.IMAGES).length;

  const toggleOpacity = (node) => {
    if (!node) return;
    node.classList.toggle('opacity-0');
  };

  /**
   * @param {number} delta
   */
  const updateActiveIndustryByDelta = (delta) => {
    const images = document.querySelectorAll(INDUSTRIES_SELECTOR.IMAGES);
    const descriptions = document.querySelectorAll(INDUSTRIES_SELECTOR.DESCRIPTIONS);
    toggleOpacity(images[activeIndustry]);
    toggleOpacity(descriptions[activeIndustry]);

    activeIndustry = (activeIndustry + delta + INDUSTRIES_COUNT) % INDUSTRIES_COUNT;
    toggleOpacity(images[activeIndustry]);
    toggleOpacity(descriptions[activeIndustry]);
  };

  document.querySelector(INDUSTRIES_SELECTOR.LEFT_BUTTON)?.addEventListener('click', () => {
    updateActiveIndustryByDelta(-1);
  });

  document.querySelector(INDUSTRIES_SELECTOR.RIGHT_BUTTON)?.addEventListener('click', () => {
    updateActiveIndustryByDelta(1);
  });

  toggleOpacity(document.querySelector(INDUSTRIES_SELECTOR.IMAGES));
  toggleOpacity(document.querySelector(INDUSTRIES_SELECTOR.DESCRIPTIONS));
};
