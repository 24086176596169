const CALLBACK_FORM_SELECTOR = '#callback-form';
const CALLBACK_BUTTON_SELECTOR = '#callback-form-submit';
const CALLBACK_SUCCESS_SELECTOR = '#callback-form--success';
const CALLBACK_FAILURE_SELECTOR = '#callback-form--error';
const CALLBACK_FAILURE_MESSAGE_SELECTOR = '#callback-form--error-message';

export const initDemoBooking = () => {
  const BOOK_DEMO_MODAL = document.querySelector('#book-demo-modal');
  const BOOK_DEMO_MODAL_BACKDROP = document.querySelector('#book-demo-modal--backdrop');
  const BOOK_DEMO_MODAL_PANEL = document.querySelector('#book-demo-modal--panel');
  const BOOK_DEMO_MODAL_CLOSE_BUTTONS = document.querySelectorAll('.book-demo-modal--close');
  const BOOK_DEMO_BUTTONS = document.querySelectorAll('.book-demo-button');

  const callbackForm = document.querySelector(CALLBACK_FORM_SELECTOR);
  const callbackButton = document.querySelector(CALLBACK_BUTTON_SELECTOR);

  $(CALLBACK_FORM_SELECTOR)
    .on('ajax:send', () => {
      callbackButton.disabled = true;
      callbackButton.innerHTML = 'Loading...';
    })
    .on('ajax:success', () => {
      callbackButton.disabled = false;
      const side = document.querySelector(CALLBACK_SUCCESS_SELECTOR);
      callbackForm.classList.add('hidden');
      side.classList.remove('hidden');
      side.classList.remove('opacity-0');
    })
    .on('ajax:error', (evt) => {
      callbackButton.disabled = false;
      const side = document.querySelector(CALLBACK_FAILURE_SELECTOR);
      callbackForm.classList.add('hidden');
      side.classList.remove('hidden');
      side.classList.remove('opacity-0');

      const [data] = evt.detail;
      document.querySelector(CALLBACK_FAILURE_MESSAGE_SELECTOR).innerHTML = data.errors[0].message;
    });

  BOOK_DEMO_BUTTONS.forEach((button) => {
    button.addEventListener('click', () => {
      BOOK_DEMO_MODAL.classList.remove('hidden');
      document.body.style.overflowY = 'hidden';
      setTimeout(() => {
        BOOK_DEMO_MODAL_BACKDROP.classList.add('opacity-100');
        BOOK_DEMO_MODAL_PANEL.classList.add('opacity-100', 'translate-y-0', 'sm:scale-100');
      }, 1);
    });
  });

  BOOK_DEMO_MODAL_CLOSE_BUTTONS.forEach((button) => {
    button.addEventListener('click', () => {
      BOOK_DEMO_MODAL_BACKDROP.classList.remove('opacity-100');
      document.body.style.overflowY = 'auto';
      BOOK_DEMO_MODAL_PANEL.classList.remove('opacity-100', 'translate-y-0', 'sm:scale-100');
      setTimeout(() => {
        BOOK_DEMO_MODAL.classList.add('hidden');
      }, 1);
    });
  });
};
