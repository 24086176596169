export const initAdditionalFeatures = () => {
  const isMobile = window.innerWidth < 768;

  if (isMobile) {
    // Get all feature buttons
    const featureButtons = document.querySelectorAll('button[aria-haspopup="dialog"]');
    const dialog = document.getElementById('feature-popup');
    const closeButton = dialog.querySelector('button[aria-label="Close popup"]');
    const popupTitle = document.getElementById('popup-title');
    const popupContent = document.getElementById('popup-content');
    const popupIcon = document.getElementById('popup-icon');

    // Add click event to all feature buttons
    featureButtons.forEach((button) => {
      button.addEventListener('click', () => {
        const container = button.closest('.feature-container');

        // Find the elements with the specific classes
        const title = container.querySelector('.feature-title').textContent;
        const content = container.querySelector('.feature-description').textContent;
        const iconSrc = container.querySelector('.feature-image').getAttribute('src');

        popupTitle.textContent = title;
        popupContent.textContent = content;
        popupIcon.setAttribute('src', iconSrc);

        // Open the dialog
        dialog.showModal();
        button.setAttribute('aria-expanded', 'true');
      });
    });

    // Close dialog when close button is clicked
    closeButton.addEventListener('click', () => {
      dialog.close();
      // Reset aria-expanded on all buttons
      featureButtons.forEach((button) => {
        button.setAttribute('aria-expanded', 'false');
      });
    });

    // Close dialog when clicking outside of it
    dialog.addEventListener('click', (event) => {
      if (event.target === dialog) {
        dialog.close();
        // Reset aria-expanded on all buttons
        featureButtons.forEach((button) => {
          button.setAttribute('aria-expanded', 'false');
        });
      }
    });
  }

  // Add window resize event to handle changing between mobile and desktop
  window.addEventListener('resize', () => {
    const currentIsMobile = window.innerWidth < 768;
    // If changed from mobile to desktop, close any open dialogs
    if (!currentIsMobile && isMobile) {
      const dialog = document.getElementById('feature-popup');
      if (dialog && dialog.open) {
        dialog.close();
      }
    }
  });
};
